<template>
  <div id="trends">
    <!-- 横幅图  -->
    <div class="multiple_banner" v-for="item in banners" :key="item.id" v-show="tabIndex === item.id">
      <el-image style="width: 100%;height: 219px;border:0;" :src="item.url" fit="fill">
        <div slot="error" class="image-slot">
          <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
        </div>
      </el-image>
    </div>
    <!--  主体内容  -->
    <div class="multiple_main">
      <div class="multiple_warp">
        <!--    主体标题    -->
        <div class="multiple_title">
          <div class="multiple_title_main">{{ tabs[tabIndex].title }}</div>
          <div class="multiple_title_path">网站首页/公司动态/{{ tabs[tabIndex].title }}</div>
        </div>
        <div class="multiple_content">
          <!--    tab切换      -->
          <div class="multiple_content_left">
            <div class="multiple_item_warp" v-for="item in tabs" :key="item.id"
                 :class="[tabIndex === item.id ? 'multipleItemWarpAction' : '']" @click="multipleTabClick(item)">
              {{ item.title }}
            </div>
          </div>
          <!--    tab切换内容      -->
          <div class="multiple_content_right">
            <div class="list_title_warp">
              <div class="list_title_txt">
                {{ tabs[tabIndex].title }}
              </div>
              <div class="list_title_null">
              </div>
            </div>
            <!--     内容数据       -->
            <div class="content_list_warp">
              <div class="list_item_warp" v-for="item in data" :key="item.id">
                <el-link @click="articleClick(item.id)" :underline="false">
                  <el-card class="box-card" shadow="hover" style="width: 100%; height: 161px;">
                    <div class="item_warp_content">
                      <div class="item_warp_left">
                        <el-image style="width:200px; height: 150px; border:0;" :src="preview+item.fileId" fit="fill">
                          <div slot="error" class="image-slot">
                            <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                          </div>
                        </el-image>
                      </div>
                      <div class="item_warp_right">
                        <div class="list_item_title">{{ item.arTitle }}</div>
                        <div class="list_item_time">{{ item.releaseTime }}</div>
                        <div class="list_item_summary">{{ item.arAbstract }}</div>
                      </div>
                    </div>
                  </el-card>
                </el-link>
              </div>
            </div>
            <!--    内容分页      -->
            <div class="content_pages_warp">
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :hide-on-single-page="true"
                  :page-size="10"
                  layout="prev, pager, next, jumper"
                  :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'trends',
  data() {
    return {
      defalutUrl: require("../../public/statics/error/zwtp.jpg"),
      //横幅图
      banners: [
        {
          id: 0,
          url: "/statics/banner/trends/banner_news.png"
        },
        {
          id: 1,
          url: "/statics/banner/trends/banner_dynamic.png"
        },
        {
          id: 2,
          url: "/statics/banner/trends/banner_building.png"
        },
      ],
      //tab切换下标
      tabIndex: 0,
      //tab切换
      tabs: [
        {
          id: 0,
          title: "公司新闻",
          arCatalog: "news",
        },
        {
          id: 1,
          title: "项目动态",
          arCatalog: "project_dynamics",
        },
        {
          id: 2,
          title: "党建专栏",
          arCatalog: "party_building_column",
        },
      ],
      //文章类型
      arCatalog: "news",
      //数据
      data: [],
      //总数
      total: 0
    }
  },
  watch: {
    //监听路由变化
    $route: {
      handler() {
        this.getBanners();
        this.getData(1, "news");
        this.tabIndex = 0;
        this.arCatalog = "news";
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //获取横幅图
    async getBanners() {
      let banners1 = await this.request.get('/banner/getInfo?key=' + this.config.key + "&bannerType=5")
      if (banners1.data && banners1.data.fileId) {
        this.banners[0].url = this.preview + banners1.data.fileId;
      }
      let banners2 = await this.request.get('/banner/getInfo?key=' + this.config.key + "&bannerType=6")
      if (banners2.data && banners2.data.fileId) {
        this.banners[1].url = this.preview + banners2.data.fileId;
      }
      let banners3 = await this.request.get('/banner/getInfo?key=' + this.config.key + "&bannerType=7")
      if (banners3.data && banners3.data.fileId) {
        this.banners[2].url = this.preview + banners3.data.fileId;
      }
    },
    //文章详情点击（包括：公司新闻、项目动态、党建专栏、项目成果、行政法规、行政动态、招标信息、中标信息）
    articleClick(id) {
      this.$router.push({path: "/article", query: {"active": 3, "id": id}});
    },
    //tab点击切换事件
    multipleTabClick(item) {
      this.tabIndex = item.id;
      this.arCatalog = item.arCatalog;
      this.getData(1, this.arCatalog);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.getData(val, this.arCatalog);
    },
    //获取数据
    getData(val, arCatalog) {
      this.arCatalog = arCatalog;
      this.request.get('/article/list?key=' + this.config.key + "&pageNum=" + val + "&pageSize=10&arCatalog=" + this.arCatalog).then(res => {
        this.data = res.data;
        this.total = res.total;
      });
    }
  }
}
</script>
<style>
@import "../assets/styles/pages/multiple.css";
</style>
